@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-scrollbar {
    width: 1px;
  }
}
@layer components {
  .content-container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  .content-container-tabs {
    height: calc(100vh - 190px);
    overflow-y: scroll;
  }
  .container-products {
    height: calc(100vh - 100px);
    overflow-y: scroll;
  }
}
